//var width = $(window).width();


$(document).ready(function(){
	
	var $body = $('body'),
		device = $body.attr('data-device'),
		thisPage = $body.attr('data-page'), 
		thisSite = $body.attr('data-site'), 
		$headerWrapper = $('#headerWrapper'),
		$mainLogo = $('#mainLogo'),
		$postBackButton = $('#postBackButton'),
		timeout,
		href,
		link,
		isMenuOpen = true,
		stateObj = new Object(),
		overlayState = 0,
		windowHeight = $(window).height(),
		postOpen = false,
		collectionOpen = false,
		postType,
		level1Load,
		triggerPoint = windowHeight/4,
		st,
		logoRatio,
		thisLevel = '',
        currentThumbs,
        currentThumbsArray;
			
		stateObj = { level1: '', level2: ''}
	
	$(window).resize(function () {
		windowHeight = $(window).height();
		triggerPoint = windowHeight/4;
	});	
	
		
		
	var $collectionsMenuButton = $('#collectionsMenuButton'),
		$collectionsSubMenu = $('#collectionsSubMenu'),
		$collectionListLi = $collectionsSubMenu.find('li'),
		$collectionListLink = $collectionsSubMenu.find('a.openOverlay'),
		$mainNav = $('#mainNav'),
		subMenuOpen = false;
	
	$collectionsMenuButton.on( 'click', function(e) {
		e.preventDefault();
		subMenuOpen = !subMenuOpen;
		
		if(subMenuOpen) {
			$mainNav.addClass('active');
			TweenMax.staggerTo($collectionListLi, 0.2, {autoAlpha:1, display:"block"}, 0.05);
		} else {
			$mainNav.removeClass('active');
			TweenMax.staggerTo($collectionListLi, 0.2, {autoAlpha:0, display:"none"}, -0.05);
		}
		
	});	
	
	$collectionListLink.on( 'click', function(e) {
		e.preventDefault();
		subMenuOpen = false;
		if (thisLevel != '') {
			destroyLayer();
		}
		$mainNav.removeClass('active');
		TweenMax.staggerTo($collectionListLi, 0.2, {autoAlpha:0, display:"none"}, -0.05);
	});

    function destroyLayer() {  console.log('destroy');
			overlayState--;		
			
			window.history.pushState('', '', historyURLs[overlayState]);
			historyURLs.splice(-1,1);
			
			$('#' + thisLevel).fadeOut(300, function() {	
				$('#' + thisLevel).remove();
				
					thisLevel = 'level' + overlayState;	
				
				if (overlayState == 0) {
					
					$postBackButton.fadeOut();
					
					if (device != 'mobile') {
						
						if (allThumbs) {
							allThumbs.sort(function(){return 0.5-Math.random()});				
							TweenMax.staggerTo(allThumbs, 0.1, {autoAlpha:1, ease: Quad.easeInOut}, 0.03); 
						} else {
							TweenMax.staggerTo(choiceThumbs, 0.05, { autoAlpha: 1, ease:Power1.easeInOut }, 0.03);
						}
							
					} else {
						
						if ($wrapper.length == 1) { 
							$wrapper.animate({'opacity':'1'},300, function() { 
								hideThumbsComplete();
							});	
						} else {
							
							$('#choiceWrapper').animate({'opacity':'1'},300, function() { 
								hideThumbsComplete();
							});	
						}		
						
						
					}				
				
					
					$body.removeClass('stop-scrolling');
						
				} else {
					
					prevLevel = '#level' + (overlayState);		
					prevThumbs = $(prevLevel).find('a');		
					
					if (device != 'mobile') {		
						prevThumbsArray = prevThumbs.toArray();
						prevThumbsArray.sort(function(){return 0.5-Math.random()});
						TweenMax.staggerTo(prevThumbsArray, 0.1, { autoAlpha: 1, ease: Quad.easeInOut}, 0.03);
					} else {
						$(prevLevel).animate({'opacity':'1'},300, function() { 
							
						});	
					}	
														
				}				
				
			});
		}


        function hideThumbsComplete() { 
			
			if (postType == 'post') {
				loadPost();
			} else {
				loadCollection();
			}			
			
			historyURLs.push(href);	
			window.history.pushState('', '', href);
				
		}
		
		
		
		
		function loadPost() {
			
			$('#' + thisLevel).show().find('#ajaxContainer').load(link, function() {
				
				if (contentType == 'Video') {
					$body.addClass('dark');
				}
				$headerWrapper.fadeIn();
				$postBackButton.fadeIn();
										
				slideshow();	
				
				$('#wrapper').disablescroll("undo");
									
			});
			
		}
		
		
		
		
		function loadCollection() {
			
			$('#' + thisLevel).show().find('#ajaxContainer').load(link, function() {
				
				colectionThumbs = $('#' + thisLevel).find('.choiceThumb').toArray();				
				
				$headerWrapper.fadeIn();
				$postBackButton.fadeIn();
				
				colectionThumbs.sort(function(){return 0.5-Math.random()});				
		
				if (device != 'mobile') {
					$('.choiceThumb').imagesLoaded(function() {
						TweenMax.staggerTo(colectionThumbs, 0.05, { autoAlpha: 1, ease:Power1.easeInOut }, 0.03);
						$loader.fadeOut();					
					});
				} else {
					$('.choiceThumb').imagesLoaded(function() { 
						$('.choiceThumb').animate({'opacity':'1'},300, function() { });
					});
				}
				
				$('#wrapper').disablescroll("undo");
						
			});	
			
		}


		
	
if (device == 'desktop') {		
		
		if (!$body.hasClass('stop-scrolling')) {
			$headerWrapper.mouseenter(function() {
			    window.clearTimeout(timeout);
			    $headerWrapper.addClass('active');
			  
			}).mouseleave(function() {
				
				window.clearTimeout(timeout);
				timeout = window.setTimeout(function(){
					$headerWrapper.removeClass('active');
				}, 1000);
				
			});
		} else {
			$headerWrapper.unbind("mouseenter");
			$headerWrapper.unbind("mouseleave");
		}
		
	} else {
				
		$mainLogo.on( 'click', function(e) { 

			e.preventDefault();
				
			isMenuOpen = !isMenuOpen;
			
			if ( isMenuOpen ) {
				$headerWrapper.removeClass('active');
			} else {
				$headerWrapper.addClass('active');
			}
			
		});
		
	}

		
		
		
	
	if (thisPage == 'homepage') { 		
		
		var $heroLogo = $('#heroLogo'),
			$pageTitle = $heroLogo.find('h1'),
			allThumbs = $('.thumbWrapper').toArray();
		
		setTimeout(function(){ 
			$heroLogo.fadeIn(800);	
			$pageTitle.fadeIn(800);	
		}, 100);
		
		if (device != 'mobile') {
			crazyScroll();	
		}
		
		
		
		
		window.addEventListener("orientationchange", function() {
		if (window.orientation == 90 || window.orientation == -90) {
			// landscape mode
			crazyScroll();	
		} else {
			// portrait mode
			crazyScroll();	
		}
		});

		
		$(window).on('scroll',homepageScroll);		
	
		
	}
	
	
	$('#wrapper').css({'opacity':1});
	
	
	function homepageScroll() {
		
		st = $(this).scrollTop()
			
		// Fade out main logo during first viewport height scroll
		if (st<=triggerPoint) {			
			logoRatio = Math.round(st/triggerPoint * 100) / 100;	
			$('.headerWrapperHome').css({'opacity':'0'});
		} else {
			logoRatio = 1;		
			$('.headerWrapperHome').css({'opacity':'1'});
		}	
		
		//$('#wrapper').css({'opacity':logoRatio});
		$('#heroLogo').css({'opacity':1-logoRatio});
		$('#scrollArrow').css({'opacity':1-logoRatio});
		
	}
	
	

	if ( (thisPage == 'homepage') || (thisPage == 'singleCollection') ) { 
						
		var $wrapper = $('#wrapper'),
			$loader = $('#loader'),
			$wrapperImages = $wrapper.find('img'),
			countImages = $wrapperImages.length,
			$scrollArrow = $('#scrollArrow'),
			$ajaxWrapperPost = $('#ajaxWrapperPost'),
			$ajaxContainerPost = $('#ajaxContainerPost'),
			$ajaxWrapperCollection = $('#ajaxWrapperCollection'),
			$ajaxContainerCollection = $('#ajaxContainerCollection'),
			choiceThumbs;

            
            $scrollArrow.on('click', function(e){
                var scrollpoint = triggerPoint * 1.5;
                window.scrollTo(0, scrollpoint);
            });

		$wrapper.imagesLoaded()
			.always( function( instance ) {				
				$loader.fadeOut();
				$scrollArrow.fadeIn();
	  		})
			.progress( function( instance, image ) {
				if(image.isLoaded) {
					$(image.img).addClass('loaded');
					var countLoadedImages = $('#wrapper img.loaded').length;
					var progressWidth = 100 * (countLoadedImages / countImages) + '%';
					$loader.css({'width' : progressWidth});
				}
		});		

		
		var prevOverlayState,
			prevLevel,
			prevThumbs,
			prevThumbsArray,
			colectionThumbs,
			contentType,
			$ajaxLayers = $('#ajaxLayers'),
			historyURLs = [thisSite],
			$wrapper = $('#wrapper');
			
			
		  
		$body.on('click', '.openOverlay', function(e){
			e.preventDefault();
			
			$('#wrapper').disablescroll();
			$body.addClass('stop-scrolling');
							
			$loader.css({'width':0})
			$loader.fadeIn();	
			
			href = $(this).attr('href');
			postType = $(this).attr('data-posttype');		
			contentType = $(this).attr('data-contenttype');	
			link = href + ' #ajaxHolder';							
			
			prevOverlayState = overlayState;
			
			overlayState++;	
			
			thisLevel = 'level' + overlayState;			
					
			
			var newDiv = $('<div/>', { 'id': thisLevel, 'class': 'ajaxWrapper'});
			var newDivInner= $('<div/>', { 'id': 'ajaxContainer'});
			
			newDiv.append(newDivInner);
			
			$ajaxLayers.append(newDiv);			
			
			//If there is already a layer open then hide its contents, otherwise hide homepage elements			
			if (prevOverlayState > 0) {
				
				prevLevel = '#level' + prevOverlayState;				
				prevThumbs = $(prevLevel).find('a');				
					
				
				if (device != 'mobile') {			
					prevThumbsArray = prevThumbs.toArray();
					prevThumbsArray.sort(function(){return 0.5-Math.random()});
					TweenMax.staggerTo(prevThumbsArray, 0.1, { autoAlpha: 0, ease: Quad.easeInOut }, 0.03, hideThumbsComplete);
				} else {
					$(prevLevel).animate({'opacity':'0'},300, function() { 
						hideThumbsComplete();
					});
				}															
			
			} else {
				
				if (device != 'mobile') {
					
					if (allThumbs) {
						allThumbs.sort(function(){return 0.5-Math.random()});				
						TweenMax.staggerTo(allThumbs, 0.1, { autoAlpha:0, ease: Quad.easeInOut }, 0.03, hideThumbsComplete); 	
					} else {
						TweenMax.staggerTo(choiceThumbs, 0.05, { autoAlpha: 0, ease:Power1.easeInOut }, 0.03, hideThumbsComplete);
					}
									
						
				} else {
					if ($wrapper.length == 1) { 
						$wrapper.animate({'opacity':'0'},300, function() {
							hideThumbsComplete();
						});	
					} else {
						
						$('#choiceWrapper').animate({'opacity':'0'},300, function() {
							hideThumbsComplete();
						});	
					}		
				}
				
			}
 
 		});
		
		
		
		
		
		
		
		
		$postBackButton.on('click', function(e) {   
			e.preventDefault();
			
			if (postType == 'post') {
				$body.removeClass('dark');		
				
				destroyLayer();

			} else {
			
				currentThumbs = $('#' + thisLevel).find('a');
				
				if (device != 'mobile') {				
					currentThumbsArray = currentThumbs.toArray(); 
					currentThumbsArray.sort(function(){return 0.5-Math.random()});
					TweenMax.staggerTo(currentThumbsArray, 0.1, { autoAlpha: 0, ease: Quad.easeInOut}, 0.03, destroyLayer);		
				} else {
					
					console.log(thisLevel);
					$('#' + thisLevel).animate({'opacity':'0'},300, function() { 
						destroyLayer();
					});	
					
					
				}	
					

			}							

		});


		
				
				
		/* ==========================================================================
		   HISTORY
		   ========================================================================== */
		
			window.onpopstate = function() {
								
				if (postType == 'post') {
					$body.removeClass('dark');		
					
					destroyLayer();
	
				} else {
				
					currentThumbs = $('#' + thisLevel).find('a');				
					currentThumbsArray = currentThumbs.toArray(); 
					currentThumbsArray.sort(function(){return 0.5-Math.random()});
					TweenMax.staggerTo(currentThumbsArray, 0.1, { autoAlpha: 0, ease: Quad.easeInOut}, 0.03, destroyLayer);				
	
				}				
			};						
			
						
	} // homepage,archive,single
	
	
	
			
	
	
	if (thisPage == 'single') {
		
		slideshow();
		
	}
	 
	
	
	
	if (thisPage == 'singleCollection') {
		
		collectionOpen = true; 
		level1Load = true;

		choiceThumbs = $('.choiceThumb').toArray();	
		choiceThumbs.sort(function(){return 0.5-Math.random()});	
		
		
		
		if (device != 'mobile') {
			$('.choiceThumb').imagesLoaded(function() {
				TweenMax.staggerTo(choiceThumbs, 0.05, { autoAlpha: 1, ease:Power1.easeInOut }, 0.03);
				$loader.fadeOut();					
			});
		} else {
			$('.choiceThumb').imagesLoaded(function() { 
				$('.choiceThumb').animate({'opacity':'1'},300, function() { });
			});
		}
		
		
	
	}




function slideshow() { 
	
	var $slideWrapper = $('#slideWrapper'),
		$slideWrapperImages = $slideWrapper.find('img'),
		countImages = $slideWrapperImages.length,
		$slides = $slideWrapper.find('.slide'),
		$loader = $('#loader'),
		$current,
		$nextArrow = $('#nextArrow'),
		$previousArrow = $('#previousArrow'),
		$mobileTapMessage = $('#mobileTapMessage'),
		$slideNumber = $('#slideNumber'),
		currentNumber = 1;
		

	function addActiveClass() { 
		$slides.first().addClass('active');
	}	
	
	$slideWrapper.imagesLoaded()
		.always( function( instance ) {				
			$loader.fadeOut();				
	
			TweenMax.to($slides.first(), 0.3, {
				autoAlpha:1 , ease:Power1.easeInOut, onComplete:addActiveClass 			
			});				
							
  		})
		.progress( function( instance, image ) {
			if(image.isLoaded) {
				$(image.img).addClass('loaded');
				TweenMax.to(image.img, 0.3, {
					autoAlpha:1, ease:Power1.easeInOut		
				});	
				var countLoadedImages = $('#slideWrapper img.loaded').length;
				var progressWidth = 100 * (countLoadedImages / countImages) + '%';
				$loader.css({'width' : progressWidth});
		  	}
	});	
	
	
	$nextArrow.on('click', function(e) {
		e.preventDefault();
		
		$mobileTapMessage.fadeOut();
		$current = $slideWrapper.find('.active');
		
		if ($current.next().length) {
			$current.removeClass('active').next().addClass('active');
			currentNumber++;			
		} else {
			$current.removeClass('active');
			$slides.first().addClass('active');
			currentNumber = 1;
		}
		$slideNumber.text(currentNumber);

	});
	
	$previousArrow.on('click', function(e) {
		e.preventDefault();
		
		$mobileTapMessage.fadeOut();
		$current = $slideWrapper.find('.active');
		
		if ($current.prev('.slide').length) {
			$current.removeClass('active').prev('.slide').addClass('active');
			currentNumber--;
		} else {
			$current.removeClass('active');
			$slides.last().addClass('active');
			currentNumber=countImages;
		}
		$slideNumber.text(currentNumber);

	});
	

	
}
		
	




function crazyScroll() {
	
	// Detect css transform
		var cssTransform = (function(){
			    var prefixes = 'transform webkitTransform mozTransform oTransform msTransform'.split(' '), 
			    el = document.createElement('div'), 
			    cssTransform,
			    i = 0;
			    while( cssTransform === undefined ){ 
			        cssTransform = el.style[prefixes[i]] !== undefined ? prefixes[i] : undefined;
			        i++;
			     }
			    return cssTransform;
			 	})();
		 
		// Detect request animation frame and set up vars		
		var scroll = window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || window.msRequestAnimationFrame || window.oRequestAnimationFrame || function(callback){ window.setTimeout(callback, 1000/60) },	
		
		
		lastPosition = -1,
		wHeight = window.innerHeight,
		wWidth = window.innerWidth,
		wrapper = document.getElementById("wrapper"),
		elements = document.getElementsByClassName("thumbWrapper"),
		size = elements.length,
		matrix = [],
		
		gridSize = 50,
		widthUnits,
		heightUnits,
		newWidth,
		newHeight,
		newPostition,
		imagePosOffset,
		imagePos,
		video,
		isPlaying,
		doit;		
		
		// Let's do this
		sizes();
		loop();

		
		window.onresize = function(){
		
			clearTimeout(doit);
			
			if (wWidth>640) { 
				
				var i = 0;
				
				for (i =0; i<size; i++){
					matrix[i].el.style.opacity = 0;
				}
			}
				
			doit = setTimeout(resizedw, 100);
			
		};		
				
		
		function resizedw(){
		    // Haven't resized in 100ms!			   
			sizes();				
		}
	
		// Pre calculate sizes 
		function sizes(){
		    lastPosition = -1; // Force a recalculation
		    wHeight = window.innerHeight;
		    wWidth = window.innerWidth;		    
		    
		    widthUnits = Math.round(wWidth / gridSize);
		    heightUnits = Math.round(wHeight / gridSize);	    	    
		    
		    var i = 0;
            var tSize;
	
		    for (i =0; i<size; i++){
			    
			    // Get the element
		        matrix[i] = matrix[i] || { el: elements[i] }		
		        
		        matrix[i].el.style.opacity = 1;        
				  
		        matrix[i].height = wHeight / 4; // 4 per page 
		        matrix[i].start = matrix[i-1] ? matrix[i-1].stop : 0; // start position is either 0 or last elements stop position
		        matrix[i].stop = matrix[i-1] ? matrix[i-1].stop + matrix[i].height : matrix[i].height; // stop position is either the last elements stop + this height, or just this height		
						 
				matrix[i].moveType = matrix[i].el.dataset.direction; // Get the move direction from the class        
		        matrix[i].el.style.zIndex = 100 + i; // increment z-index so elements sit on top of their predecessor		
		        
		        matrix[i].elementType = matrix[i].el.dataset.type;
		        
		        matrix[i].elementThumbSize = matrix[i].el.dataset.thumbnailsize;
		        			
				matrix[i].elementWidth = matrix[i].el.dataset.w; // Get the image width  
				matrix[i].elementHeight = matrix[i].el.dataset.h; // Get the image height
				
				matrix[i].ratio = matrix[i].elementWidth / matrix[i].elementHeight;	
		    
				if ( matrix[i].ratio >= 1 ) {
					
					matrix[i].orient = 'landscape';				
					// landscape - Set up img to be between 20-40% viewport width
					//newWidth = getRandomNumber(8,widthUnits*0.4);
					
					tSize = matrix[i].elementThumbSize;
					if (tSize == "Normal") {
						newWidth = widthUnits*0.3;
					} else if (tSize == "Large") {
						newWidth = widthUnits*0.5;
					} else if (tSize == "X-Large") {
						newWidth = widthUnits*0.7;
					}
					if (newWidth < 8) { newWidth = 8; }
					
					newWidth = newWidth * gridSize;
			        newHeight = (newWidth / matrix[i].ratio);	        
			       			
				} else {
					
					matrix[i].orient = 'portrait';
					// Portrait - Set up img to be between 20-40% viewport height
					//newHeight = getRandomNumber(8,heightUnits*0.4) * gridSize;
					
					tSize = matrix[i].elementThumbSize;
					if (tSize == "Normal") {
						newHeight = heightUnits*0.3;
					} else if (tSize == "Large") {
						newHeight = heightUnits*0.5;
					} else if (tSize == "X-Large") {
						newHeight = heightUnits*0.7;
					}
					if (newHeight < 8) { newHeight = 8; }
					
					newHeight = newHeight * gridSize;
					
					newWidth = (newHeight * matrix[i].ratio);	       
		
				}	
				
				matrix[i].newWidth = newWidth;
				matrix[i].newHeight = newHeight; 
				
				matrix[i].el.style.width = newWidth + 'px';
				matrix[i].el.style.height = newHeight + 'px';			
		               
		            
			    if ( (matrix[i].moveType == 'left') || (matrix[i].moveType == 'right') ) {
			        
			        // Randomly place image in the Y axis
			        imagePosOffset = wHeight - matrix[i].newHeight - 250; 
			        imagePosOffset = imagePosOffset / gridSize;
			        
			        imagePos = 150 + (getRandomNumber(1,imagePosOffset) * gridSize); 
			        matrix[i].el.style.top = imagePos + 'px';
			        
			        // Calculate image's travel disance
			        matrix[i].moveDistance = wWidth + (2 * matrix[i].newWidth);	
			        
			        // position image in start position
			        newPostition = -matrix[i].newWidth;	                
					horizontalMotion(matrix[i].el, newPostition);                
			        
			    } else {		   
			        
			        // Randomly place image in the X axis
			        imagePosOffset = wWidth - matrix[i].newWidth - 200;
			        imagePosOffset = imagePosOffset / gridSize;
			        
			        imagePos = 150 + (getRandomNumber(1,imagePosOffset) * gridSize); 
			        matrix[i].el.style.left = imagePos + 'px';
			        
			        // Calculate image's travel disance
			        matrix[i].moveDistance = wHeight + (2 * matrix[i].newHeight);
			        
			        // position image in start position
			        newPostition = -matrix[i].newHeight;	                
					verticalMotion(matrix[i].el, newPostition);	 
		
			    }
		
		    }
		          
		    // Set document height to be the height off the last element + 1.5 x viewport height
		    wrapper.style.height = (matrix[i-1].stop + (1.5 * wHeight )) + "px";
		    
		} 
			
		
		function verticalMotion(m, t){
		    if (cssTransform)
		        m.style[cssTransform] = "translate3d(0, "+ t +"px,0)";
		    else
		        m.style.top = t;
		}
		
		function horizontalMotion(m, t){  
		    if (cssTransform)
		        m.style[cssTransform] = "translate3d("+ t +"px,0,0)";
		    else
		        m.style.left = t;
		}
		
		// get a random number between given range	
		function getRandomNumber(min, max) {
			//return Math.random() * (max - min) + min;
			return Math.floor(Math.random() * (max - min + 1)) + min;
		}
		
		
		function loop(){
		    // Avoid calculations if not needed as loop is always running
		    if (lastPosition == window.pageYOffset) {
		        scroll(loop);
		        return false;
		    } else lastPosition = window.pageYOffset;
			
					
			// loop through all sections
			
			if (wWidth>640) { 
			
			    var i = 0;
			    for (i =0; i<size; i++){
			
		             // Is it visible right now?
		             if (lastPosition >= matrix[i].start - wHeight && lastPosition <= (matrix[i].start+wHeight)){
			             
			            matrix[i].el.style.display = 'block';
			             
						if ( matrix[i].elementType == 'video') {
							
							video = matrix[i].el.children[0];
							
							if (device == 'desktop') {							
								isPlaying = video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2;
								
								if (!isPlaying) {
								  video.play();
								}	
							}	
							
						}
			            
			            var scrollRatio = (lastPosition - matrix[i].start)/ wHeight;                    
		               
		                if (matrix[i].moveType == 'left') {	                
			                                
			                newPostition = -matrix[i].newWidth + (matrix[i].moveDistance * scrollRatio);	                
			                horizontalMotion(matrix[i].el, newPostition);
			                
		                } else if (matrix[i].moveType == 'right') {
							
							newPostition = wWidth - (matrix[i].moveDistance * scrollRatio);	                
							horizontalMotion(matrix[i].el, newPostition);
			                
		                } else if (matrix[i].moveType == 'bottom') {
							
							newPostition = wHeight - (matrix[i].moveDistance * scrollRatio);	                
							verticalMotion(matrix[i].el, newPostition);
			               
		                } else {
			            
							newPostition = -matrix[i].newHeight + (matrix[i].moveDistance * scrollRatio);	                
							verticalMotion(matrix[i].el, newPostition);
		
			            }
		                
		            // not visible, so make sure it is hidden ad paused
		            } else {
			            
						matrix[i].el.style.display = 'none';
						
						if (( matrix[i].elementType == 'video') && (device =='desktop') ) {
							
							video = matrix[i].el.children[0];
							
							isPlaying = video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2;
							
							if (isPlaying) {
							  video.pause();
							}
							
						}
		
		            }	            	               
			
			    }
			    
		    }
		    scroll(loop);
		}
		
		
}







 }); // END ON READY






